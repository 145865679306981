import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  card_number: any;
  card_holder_name: string;
  expiry_date: any;
  security_code: any;
  city: string;
  state: string;
  country: string;
  zip_code: any;
  address: any;
  selectedOption: any;
  usertoken: any;


  cardnumbererror:any;
  card_holder_nameerror: string;
  expiry_dateerror: any;
  security_codeerror: any;
  cityerror: string;
  stateerror: string;
  countryerror: string;
  zip_codeerror: any;
  addresserror: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Splitpayments2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apicreate_card_informationId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      card_number: "",
      card_holder_name: "",
      expiry_date: "",
      security_code: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
      address: "",
      selectedOption: null,
      usertoken: "",


      cardnumbererror:"",
      card_holder_nameerror: "",
      expiry_dateerror: "",
      security_codeerror: "",
      cityerror: "",
      stateerror: "",
      countryerror: "",
      zip_codeerror: "",
      addresserror: "",
    
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {

        if (apiRequestCallId === this.apicreate_card_informationId) {
          if (responseJson && responseJson.message) {
            this.movedigsureScreen()
          } else {
            //Check Error Response
            this.ErroValidfuntion(responseJson)
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      this.setState({ usertoken: phoneAuthToken });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  
  ErroValidfuntion = async (responseJson: any) => {
    if (responseJson.error) {
      const processedFields = new Set(); // Initialize a Set to keep track of processed fields
      for (const key in responseJson.error) {
        if (!processedFields.has(key)) { // Check if the field has already been processed
          const errors = responseJson.error[key];
          this.handleFieldErrors(key, errors);
          processedFields.add(key); // Add the field to the processed set
        }
      }
    }
  };

  handleFieldErrors = (fieldName: string, errors: any) => {
    console.log("-0-0-0--0",fieldName,"=-=-=-==",errors)
    for (const error of errors) {
      switch (error) {
        case "can't be blank":
          this.setState({cardnumbererror:fieldName+" "+error})
          break;
        case "has already been taken":
          this.setState({cardnumbererror:fieldName+" "+error})
          break;
        case "Please enter valid expiry date":
          this.setState({expiry_dateerror:error})
          break;
        case "is the wrong length (should be 16 characters)":
          this.setState({cardnumbererror:fieldName+" "+error})
          break;
        case "is the wrong length (should be 3 characters)":
          this.setState({security_codeerror:fieldName+" "+error})
          break;
        case "only allows numbers":
          console.log("--",error)
          break;
        case "only allows letters":
          console.log("--",error)
          break;
        default:
          console.log("--",error)
          break;
      }
    }
  };
  
  
  handleCardNumberChange = (text: any) => {
    const numericValue = text.replace(/\D/g, '');

    const formattedCardNumber = numericValue
      .replace(/(\d{4})(?=\d)/g, '$1 ')
      .trim();
    if (formattedCardNumber.length <= 19) {
      this.setState({
        card_number: formattedCardNumber,
      });
    }
  }

  handleExpirationDateChange = (text: any) => {
    const numericValue = text.replace(/\D/g, '');
    const formattedExpirationDate = numericValue
      .replace(/(\d{2})(\d{2})/, '$1/$2')
      .trim();

    if (numericValue.length <= 4) {
      this.setState({
        expiry_date: formattedExpirationDate,
      });
    }
  }


  SaveCardDetails = async () => {
    this.setState({
      cardnumbererror:"",
      card_holder_nameerror: "",
      expiry_dateerror: "",
      security_codeerror: "",
      cityerror: "",
      stateerror: "",
      countryerror: "",
      zip_codeerror: "",
      addresserror: "",
    })
    const {
     usertoken
    } = this.state;

    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: usertoken

    };

    const card_numberjoin = await this.state.card_number.replace(/\s/g, '');

    const httpBody = {
      "card_params":
      {
        card_number: card_numberjoin,
        card_holder_name: this.state.card_holder_name,
        expiry_date: this.state.expiry_date,
        security_code: this.state.security_code
      },
      "billing_params":
      {
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zip_code: this.state.zip_code,
        address: this.state.address
      }

    };

    this.ApiCallCarddetails(header, httpBody)

  }

  ApiCallCarddetails = async (header: any, httpBody: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apicreate_card_informationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  movedigsureScreen = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage), "Digisureapiintegration3");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  SkipNext = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import React from "react";
import { ScrollView } from "react-native";
import { onBoardingOne, onBoardingTwo, onboardingThree } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentIndex:any;
  slides:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnboardingguideController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      currentIndex:0,
      slides:[
        {
          key: 1,
          title: 'GOLUX',
          text: 'Welcome To The Most \n Transparent Rental \n Marketplace',
          image: onBoardingOne,
          backgroundColor: '#59b2a',
        },
        {
          key: 2,
          title: 'Better traveling',
          text: 'Trasparent Pricing \n Instant Customer Service \n Innovative Technology',
          image:onBoardingTwo,
          backgroundColor: '#febe2',
        },
        {
          key: 3,
          title: 'Host, Share, Earn',
          text: 'Earn an extra income by listing \n your homes or cars on the \n GoLux platform!',
          image:onboardingThree,
          backgroundColor: '#22bcb',
        }
      ]
    };
    this.scrollView = React.createRef<ScrollView>();

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
  }

  // Customizabele Area Start
  async skipOnboarding() {
        await StorageProvider.set('onBoarding', JSON.stringify(true))

    const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

   exitOnboarding() {
    const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  scrollView: React.RefObject<ScrollView>;

  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "digisureapiintegration3";
exports.labelBodyText ="Scan Driver license";
exports.dotContaintext='Keep these tips in mind:';
exports.dottlistone ='• Make sure your license is well lit and in \n  focus.';
exports.dottlisttwo ='• Use a dark background for contrast.'
exports.dottlistthree ='• Capture the whole license in frame.';
exports.informationtext ='Your information will be stored';
exports.privacyLine="securely."
exports.privacytext='View our Privacy Policy'
exports.skiptext='Skip $ Finish Later'
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End